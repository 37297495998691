import React from "react"
import Layout from '../components/Layout'
import Form from "../components/Form/contact"
import View from "../components/View"
import ZipTmManager from '../components/ZipTmManager'
import FSFooter from '../components/FSFooter'
import FSSidebar from '../components/FSSidebar'
import styled from 'styled-components'
import { navigate, globalHistory } from '@reach/router'
import { handleRegister, isLoggedIn } from "../utils/auth"
import { parseQuery } from '../utils/query'
import { isBrowser } from "../utils/misc"

class Contact extends React.Component {
  state = {
    username: ``,
    password: ``,
    passwordConfirm: ``,
    errorMessage: ``,
  }

  componentDidMount() {
    var query = this.state.query;
    if(globalHistory.location.search){
      query = parseQuery(globalHistory.location.search)
      console.log(query)
      if(query.email){
        this.setState({
          username: query.email
        })
      }
    }

  }
  handleUpdate(event) {
    if(event?.target?.name){
      this.setState({
        [event.target.name]: event.target.value,
      })
    }else{
      console.log(event)
    }

  }

  handleSubmit(event) {
    event.preventDefault()

  }

  setErrorMessage = message => {
    this.setState({
      errorMessage: message
    })
  }

  render() {
    if(!isBrowser){
      return null
    }
    return (
      <Layout hideHeader={true}>
      <ContactWrapper id="contact-zip">
      <div className='main row'>
        <div className='sidebar col-md-2 hidden-md hidden-sm hidden-xs'>
          <FSSidebar />
        </div>
        <div className='content col-lg-10 col-md-12'>
          <div className='form-zip-wrapper'>
            <Form
              handleUpdate={e => this.handleUpdate(e)}
              handleSubmit={e => this.handleSubmit(e)}
              setErrorMessage={this.setErrorMessage}
            />
            <div className="or-divider" />
            <ZipTmManager
              setErrorMessage={this.setErrorMessage}
            />
          </div>
          <label className='status-label'>{this.state.errorMessage}</label>
          <div className='contact-block'>
            <h3 className='center'>More Ways to Contact Us</h3>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <h3>By Department</h3>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>General Inquiries</strong></p>
                <p><a href='tel:18004510410' target='_blank'>+1 (800) 451 - 0410 (Toll free)</a></p>
                <p><a href='mailto:marketing@forms-surfaces.com' target='_blank'>marketing@forms-surfaces.com</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Sales or Territory Managers</strong></p>
                <p><a href='mailto:sales@forms-surfaces.com' target='_blank'>sales@forms-surfaces.com</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Marketing</strong></p>
                <p><a href='mailto:marketing@forms-surfaces.com' target='blank'>marketing@forms-surfaces.com</a></p>
              </div>
              <div className='col-md-3 col-sm-4  hidden-sm hidden-xs'>

              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Sustainability</strong></p>
                <p><a href='mailto:green@forms-surfaces.com' target='_blank'>green@forms-surfaces.com</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Product Design</strong></p>
                <p><a href='mailto:designstudio@forms-surfaces.com' target='_blank'>designstudio@forms-surfaces.com</a></p>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <h3>By Location</h3>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Carpinteria</strong></p>
                <p>5055 6th Street</p>
                <p>Carpinteria, CA 93013</p>
                <p><a href='tel:18004510410' target='_blank'>+1 (800) 451 - 0410</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Dubai</strong></p>
                <p>B203, Building 4,</p>
                <p>Dubai Design District,</p>
                <p>Dubai, United Arab Emirates</p>
                <p>PO Box: 333101</p>
                <p><a href='tel:97145642548' target='_blank'>+971 4 5642548</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>London</strong></p>
                <p>26 Store Street</p>
                <p>London WC1E 7BT, UK</p>
                <p><a href='tel:442037553731' target='_blank'>+44 2037 - 553731</a></p>
              </div>
              <div className='col-md-3 col-sm-4 hidden-sm hidden-xs'>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Melbourne</strong></p>
                <p>HWT Tower Level 6</p>
                <p>40 City Road Southbank</p>
                <p>Melbourne, VIC 3006</p>
                <p><a href='tel:61383913105' target='_blank'>+61 3 8391 - 3105</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Phoenix</strong></p>
                <p>1025 N 71st Avenue</p>
                <p>Phoenix, AZ 85043</p>
                <p><a href='tel:18004510410' target='_blank'>+1 (800) 451 - 0410</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Pittsburgh</strong></p>
                <p>30 Pine Street</p>
                <p>Pittsburgh, PA 15223</p>
                <p><a href='tel:14127819003' target='_blank'>+1 (412) 781 - 9003</a></p>
              </div>
              <div className='col-md-3 col-sm-4  hidden-sm hidden-xs'>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Pune</strong></p>
                <p>3rd Floor, Sun Building,One Earth, Suzlon Campus</p>
                <p>Hadapsar, Pune, 411028</p>
                <p>Sales enquiries: </p>
                <p><a href='tel:917507099099' target='_blank'>+91 7507099099</a></p>
                <p>General enquiries: </p>
                <p><a href='tel:912067482400' target='_blank'>+91 2067482400</a></p>
              </div>
              <div className='col-md-3 col-sm-4 '>
                <p><strong>Singapore</strong></p>
                <p>60 Kaki Bukit Place,</p>
                <p>#02-10, EUnos Tech Park,</p>
                <p>Singapore 415979</p>
                <p><a href='6567488383' target='_blank'>+65 6748 - 8383</a></p>
              </div>
              <div className='col-md-3 col-sm-4  hidden-sm hidden-xs'>
              </div>
            </div>
          </div>

        </div>
      </div>
      <FSFooter />
      </ContactWrapper>
      </Layout>
    )
  }
}

export default Contact


const ContactWrapper = styled.section`
background: #ffffff;
color: #989898;
position: relative;
font-family: Arial,sans-serif;
font-size: .9rem;
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 92.5%;
max-width: 1800px;
margin: 0px auto;
.content {

}
h2 {
  color: #e31837;
  font-size: 1.335rem;
  font-family: Arial,sans-serif;
}
h3 {
  color: #000000;
  font-size: 1.335rem;
  font-family: Arial,sans-serif;
}
.center {
  text-align: center;
}
input[type="submit"] {
  background: #e31837;
  color: #fff;
  width: 150px;
  margin: 0;
  padding: .47rem 1rem;
  margin-bottom: 1rem;
  font-size: .89rem;
}
p{
  margin-bottom: 1rem;
}
label {
  text-transform: none;
  margin-bottom: 1rem;
  font-size: .86rem;
}
.or-divider {
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.or-divider:before {
  content: '';
  display: block;
  width: 100%;
  border-bottom: solid 1px;
}
.or-divider:after {
  content: 'OR';
  display: block;
  width: auto;
  background-color: #fff;
  margin-top: -.98rem;
  padding: .2rem;
  text-align: center;
}
section form{
  width: 100%;
}
.tm-contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}
.tm-contact {
  padding: 1rem;
  width: 100%;
  background-color: #ddd;
  color: #58595B;
  a {
    text-decoration: none;
  }
}
.tm-contact>div{
  padding-left: 2rem;
}
.tm-name {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNzc3NzggOC44ODg4OUM4LjY1NjgxIDguODg4ODkgOS41MTYwOSA4LjYyODIzIDEwLjI0NyA4LjEzOTg3QzEwLjk3NzkgNy42NTE1IDExLjU0NzUgNi45NTczOCAxMS44ODM5IDYuMTQ1MjZDMTIuMjIwMyA1LjMzMzE1IDEyLjMwODMgNC40Mzk1MiAxMi4xMzY4IDMuNTc3MzhDMTEuOTY1MyAyLjcxNTI0IDExLjU0MiAxLjkyMzMyIDEwLjkyMDUgMS4zMDE3NUMxMC4yOTg5IDAuNjgwMTgzIDkuNTA2OTggMC4yNTY4OTEgOC42NDQ4NCAwLjA4NTQwMDhDNy43ODI3MSAtMC4wODYwODkxIDYuODg5MDggMC4wMDE5MjU2OCA2LjA3Njk2IDAuMzM4MzE1QzUuMjY0ODQgMC42NzQ3MDUgNC41NzA3MiAxLjI0NDM2IDQuMDgyMzYgMS45NzUyNUMzLjU5NCAyLjcwNjEzIDMuMzMzMzMgMy41NjU0MiAzLjMzMzMzIDQuNDQ0NDVDMy4zMzMzMyA1LjYyMzE5IDMuODAxNTggNi43NTM2NSA0LjYzNTA4IDcuNTg3MTRDNS40Njg1NyA4LjQyMDY0IDYuNTk5MDQgOC44ODg4OSA3Ljc3Nzc4IDguODg4ODlaTTE0LjQ0NDQgMTlDMTQuNzM5MSAxOSAxNS4wMjE3IDE4Ljg4MjkgMTUuMjMwMSAxOC42NzQ2QzE1LjQzODUgMTguNDY2MiAxNS41NTU2IDE4LjE4MzYgMTUuNTU1NiAxNy44ODg5QzE1LjU1NTYgMTUuODI2MSAxNC43MzYxIDEzLjg0NzggMTMuMjc3NSAxMi4zODkyQzExLjgxODkgMTAuOTMwNiA5Ljg0MDU3IDEwLjExMTEgNy43Nzc3OCAxMC4xMTExQzUuNzE0OTggMTAuMTExMSAzLjczNjY3IDEwLjkzMDYgMi4yNzgwNiAxMi4zODkyQzAuODE5NDQyIDEzLjg0NzggMy4wNzM4ZS0wOCAxNS44MjYxIDAgMTcuODg4OUMwIDE4LjE4MzYgMC4xMTcwNjMgMTguNDY2MiAwLjMyNTQzNyAxOC42NzQ2QzAuNTMzODExIDE4Ljg4MjkgMC44MTY0MjYgMTkgMS4xMTExMSAxOUgxNC40NDQ0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: left center;
}
.tm-phone {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMi41QzAgMS44MzY5NiAwLjI2MzM5MiAxLjIwMTA3IDAuNzMyMjMzIDAuNzMyMjMzQzEuMjAxMDcgMC4yNjMzOTIgMS44MzY5NiAwIDIuNSAwTDEwIDBDMTAuNjYzIDAgMTEuMjk4OSAwLjI2MzM5MiAxMS43Njc4IDAuNzMyMjMzQzEyLjIzNjYgMS4yMDEwNyAxMi41IDEuODM2OTYgMTIuNSAyLjVWMTcuNUMxMi41IDE4LjE2MyAxMi4yMzY2IDE4Ljc5ODkgMTEuNzY3OCAxOS4yNjc4QzExLjI5ODkgMTkuNzM2NiAxMC42NjMgMjAgMTAgMjBIMi41QzEuODM2OTYgMjAgMS4yMDEwNyAxOS43MzY2IDAuNzMyMjMzIDE5LjI2NzhDMC4yNjMzOTIgMTguNzk4OSAwIDE4LjE2MyAwIDE3LjVWMi41Wk03LjUgMTYuMjVDNy41IDE1LjkxODUgNy4zNjgzIDE1LjYwMDUgNy4xMzM4OCAxNS4zNjYxQzYuODk5NDYgMTUuMTMxNyA2LjU4MTUyIDE1IDYuMjUgMTVDNS45MTg0OCAxNSA1LjYwMDU0IDE1LjEzMTcgNS4zNjYxMiAxNS4zNjYxQzUuMTMxNyAxNS42MDA1IDUgMTUuOTE4NSA1IDE2LjI1QzUgMTYuNTgxNSA1LjEzMTcgMTYuODk5NSA1LjM2NjEyIDE3LjEzMzlDNS42MDA1NCAxNy4zNjgzIDUuOTE4NDggMTcuNSA2LjI1IDE3LjVDNi41ODE1MiAxNy41IDYuODk5NDYgMTcuMzY4MyA3LjEzMzg4IDE3LjEzMzlDNy4zNjgzIDE2Ljg5OTUgNy41IDE2LjU4MTUgNy41IDE2LjI1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: left center;
}
.tm-contact>.tm-location.first {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuODU3MTUgMEM1Ljc3NDA2IDAuMDAyNDU3NDggMy43NzY5OSAwLjgzMTA1MSAyLjMwNDAzIDIuMzA0MDJDMC44MzEwNTkgMy43NzY5OSAwLjAwMjQ2NTg0IDUuNzc0MDUgOC4zNTkyZS0wNiA3Ljg1NzE0Qy0wLjAwMjQ4NjU2IDkuNTU5NDUgMC41NTM1NjYgMTEuMjE1NiAxLjU4Mjg3IDEyLjU3MTRDMS41ODI4NyAxMi41NzE0IDEuNzk3MTUgMTIuODUzNiAxLjgzMjE1IDEyLjg5NDNMNy44NTcxNSAyMEwxMy44ODUgMTIuODkwN0MxMy45MTY0IDEyLjg1MjkgMTQuMTMxNCAxMi41NzE0IDE0LjEzMTQgMTIuNTcxNEwxNC4xMzIyIDEyLjU2OTNDMTUuMTYwOSAxMS4yMTQgMTUuNzE2NyA5LjU1ODY2IDE1LjcxNDMgNy44NTcxNEMxNS43MTE4IDUuNzc0MDUgMTQuODgzMiAzLjc3Njk5IDEzLjQxMDMgMi4zMDQwMkMxMS45MzczIDAuODMxMDUxIDkuOTQwMjQgMC4wMDI0NTc0OCA3Ljg1NzE1IDBaTTcuODU3MTUgMTAuNzE0M0M3LjI5MjA2IDEwLjcxNDMgNi43Mzk2NiAxMC41NDY3IDYuMjY5ODEgMTAuMjMyOEM1Ljc5OTk1IDkuOTE4ODIgNS40MzM3NSA5LjQ3MjYgNS4yMTc1IDguOTUwNTJDNS4wMDEyNSA4LjQyODQ1IDQuOTQ0NjYgNy44NTM5NyA1LjA1NDkxIDcuMjk5NzRDNS4xNjUxNSA2Ljc0NTUxIDUuNDM3MjcgNi4yMzY0MiA1LjgzNjg1IDUuODM2ODRDNi4yMzY0MiA1LjQzNzI2IDYuNzQ1NTIgNS4xNjUxNCA3LjI5OTc1IDUuMDU0OUM3Ljg1Mzk4IDQuOTQ0NjYgOC40Mjg0NiA1LjAwMTI0IDguOTUwNTMgNS4yMTc0OUM5LjQ3MjYxIDUuNDMzNzQgOS45MTg4MyA1Ljc5OTk0IDEwLjIzMjggNi4yNjk4QzEwLjU0NjcgNi43Mzk2NSAxMC43MTQzIDcuMjkyMDUgMTAuNzE0MyA3Ljg1NzE0QzEwLjcxMzMgOC42MTQ2MSAxMC40MTIgOS4zNDA3OSA5Ljg3NjQxIDkuODc2NEM5LjM0MDggMTAuNDEyIDguNjE0NjIgMTAuNzEzMyA3Ljg1NzE1IDEwLjcxNDNaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: left center;
}
.status-label {
  width: 100%;
}
.contact-block {
  background-color: #e6e6e6;
  width: 100%;
  padding: 2rem;
  margin-top: 4rem;
  hr {
    margin: 3rem 0;
  }
  .row>div {
    margin-bottom: 3rem;
  }
  strong {
    color: #000000;
  }
  p {
    line-height: 1.25;
    margin-bottom: .5rem;
    color: #000000;
  }
  a {
    text-decoration: none;
    color: #000000;
    font-weight: normal;
  }
  h3 {
    margin-bottom: 3rem;
  }
}
#sidebar-left-inner {
  font-size: 0.75em;
  line-height: 1.333em;
  font-weight: 100;
}
#sidebar-left-inner>div ul {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
}
#sidebar-left-inner>div ul li {
   list-style: none;
   list-style-type: none;
   display: flex;
   flex-direction: column;
   margin: 0;
 }
#sidebar-left-inner>div ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
    color: #808285;
    text-decoration: none;
    font-weight: 100;
}
#sidebar-left-inner>div ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
}
#sidebar-left-inner ul li ul li>a {
    padding-left: 0.5rem;
}
#sidebar-left-inner>div li.active a.active {
    background-color: #e5e5e5;
}
@media(min-width: 1024px){
  .form-zip-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  section>section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  section:first-child {
    width: calc(60% - 4rem);
  }
  section#tm-zip-finder {
    width: calc(40% - 4rem);
  }
  .or-divider {
    width: 8rem;
    height: auto;
    alignItems: stretch;
    flex-direction: row;
  }
  .or-divider:before {
    border-bottom: none;
    border-right: solid 1px;
    height: 100%;
    width: 2px;
  }
  .or-divider:after {
    margin-top: 0;
    margin-left: -.9rem;
  }
}
`
