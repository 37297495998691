import React, { Component } from 'react'
import WaitLoader from '../WaitLoader'
import { getTerritoryManager } from '../../utils/tm-lookup'
import * as styles from '../Form/form.module.css'

class ZipTmManager extends Component{
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      zipCode: '',
      tmContact: []
    }
  }

  handleUpdate = e => {
    this.setState({
      zipCode: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      loading: true
    })
    getTerritoryManager(this.state.zipCode)
    .then(response => {
      this.setState({
        tmContact: response,
        loading: false
      })
      console.log(response)
    })
    .catch(err=>{
      this.setState({
        loading: false
      })
      this.props.setErrorMessage(err.message)
    })
  }

  render(){
    let loader = ''
    if(this.state.loading){
      loader = <WaitLoader loading={true} />
    }
    return(
      <section id="tm-zip-finder">
      <form className={styles.form} onSubmit={this.handleSubmit} style={{position: 'relative'}}>
        {loader}
        <h2>&nbsp;</h2>
        <p className={styles[`form__instructions`]}>
          Enter your ZIP code to find your local territory manager.
        </p>
        <div className="row">
          <div className="col-xs-12">
            <label className={styles[`form__label`]} htmlFor="tm-zip-code">
              ZIP Code
              <input
                id="tm-zip-code"
                className={styles[`form__input`]}
                type="text"
                name="tmZipCode"
                placeholder="00000"
                onChange={this.handleUpdate}
              />
            </label>
          </div>
          <div className="col-xs-12">
            <input className={styles[`form__button`]} type="submit" value="Find My Territory Manager" style={{
              width: '100%'
            }} />
          </div>
          <div className="col-xs-12 tm-contacts">
          {this.state.tmContact.map(item =>{
            return (
              <div className="tm-contact">
                <div className="tm-name">{item.Territory.match(/\(([^)]+)\)/)[1]}</div>
                <div>{item.DivisionName}</div>
                <div className="tm-phone"><a href={'tel:'+item.PrimaryPhone.replaceAll('.','')}>{item.PrimaryPhone}</a></div>
                <div><a href={'mailto:'+item.Email.toLowerCase()} target='_blank'>{item.Email.toLowerCase()}</a></div>
                {item.Territory.replace(/ *\([^)]*\) */g, "").replace(/OP/g, '').replace(/AP/g, '').split('/').map((territory, i) => {
                  return (
                    <div className={"tm-location" + (i < 1 ? ' first' : '')}>{territory}</div>
                  )
                })
                }
              </div>
            )
          })}
          </div>
        </div>
      </form>
      </section>
    )
  }
}

export default ZipTmManager
