import React, { Component } from 'react'
import styled from 'styled-components'
import * as styles from './form.module.css'

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formVisible: true,
      fileName: ''
    }
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      body: new FormData(e.target)
    })
    .then(() => {
      this.setState({
        formVisible: false
      })
    })
    .catch(err => {
      this.props.setErrorMessage(err.message)
    });

    e.preventDefault()
  }

  handleFileUpdate = (e) => {
    const files = Array.from(e.target.files)
    console.log(files)
    let filenames = files.map((item)=>{
      return item.name
    })
    this.setState({
      fileName: filenames.join(', ')
    })
  }

  render(){
    const {
      fileName,
      formVisible
    } = this.state
    let successMessage = ''
    if(!formVisible){
      successMessage = <div className="success-message">
      <h2>Thanks!</h2>
      <p>We received your information.</p>
      </div>
    }
    return(
      <ContactWrapper id="contact">
        {successMessage}
        <form
          className={styles.form}
          name="contactForm"
          method="post"
          enctype="multipart/form-data"
          data-netlify="true"
          netlify-honeypot="subject"
          action="/contact-success"
          autocomplete="on"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="subject" />
          <input type="hidden" name="form-name" value="contactForm" />
          <h2>Contact</h2>
          <p className={styles[`form__instructions`]}>
            Have a question? Send us a message using the form below.
          </p>
          <div className="row">
            <div className="col-sm-6">
              <label className={styles[`form__label`]} htmlFor="first-name">
                First Name
                <input
                  id="first-name"
                  className={styles[`form__input`]}
                  type="text"
                  name="firstName"
                  placeholder="Jane"
                  required={true}
                  onChange={this.props.handleUpdate}
                />
              </label>
            </div>
            <div className="col-sm-6">
              <label className={styles[`form__label`]} htmlFor="last-name">
                Last Name
                <input
                  id="last-name"
                  className={styles[`form__input`]}
                  type="text"
                  name="lastName"
                  placeholder="Doe"
                  required={true}
                  onChange={this.props.handleUpdate}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <label className={styles[`form__label`]} htmlFor="email">
                Email
                <input
                  id="email"
                  className={styles[`form__input`]}
                  type="email"
                  name="email"
                  placeholder="example@forms-surfaces.com"
                  required={true}
                  onChange={this.props.handleUpdate}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="phoneNumber" className={styles['form__label']}>
                Phone:
                <input
                id="phoneNumber"
                name="phone"
                type="tel"
                placeholder="+1 (XXX) XXX - XXXX"
                onChange={this.props.handleUpdate}
                />
              </label>
            </div>
            <div className="col-sm-6">
              <label className={styles[`form__label`]} htmlFor="zip-code">
                Zip Code
                <input
                  id="zip-code"
                  className={styles[`form__input`]}
                  type="text"
                  name="zipcode"
                  placeholder="00000"
                  onChange={this.props.handleUpdate}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <label className={styles[`form__label`]} htmlFor="message">
                Message
                <textarea
                  id="message"
                  name="comments"
                  className={styles['form__input']}
                  rows={6}
                  required={true}
                  onChange={this.props.handleUpdate}
                />
              </label>
            </div>
          </div>
          <div className="row mobile">
            <div className="col-xs-9"
              style={{
               display: 'flex',
               alignItems: 'flex-end',
               justifyContent: 'flex-start'
             }}>
              <label style={{
                display: 'flex',
                flexDirection: 'row'
              }} className='file-label'>
                <input name="file" type="file" onChange={this.handleFileUpdate}/>
                <div style={{
                  paddingLeft: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>{fileName}</div>
              </label>
            </div>
            <div className="col-xs-3" style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end'
            }}>
              <input className={styles[`form__button`]} type="submit" value="Submit" />
            </div>
          </div>
        </form>
      </ContactWrapper>
    )
  }
}

const ContactWrapper = styled.section`
  position: relative;
  .success-message{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #ffffff;
    z-index: 10;
    flex-direction: column;
    align-items: center;
  }
  input:not([type="submit"]),
  textarea {
    border-color: #c0c0c0;
  }
  input[type="file"]{
    position: relative;
    text-indent: -999px;
    background: #e6e6e6;
    border-color: #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 150px;
    padding: .25rem 0;
    margin: 0;
    font-size: .89rem;
  }
  .file-label {
    position: relative;
    cursor: pointer;
  }
  .file-label:before{
    content: '';
    display: block;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEzXzQxKSI+CjxwYXRoIGQ9Ik0yMi45NzM5IDkuNjIzNzRMMjIuOTczOSAyMi42MjA0QzIyLjk3MzkgMjQuMjEyNSAyMi4zNDE0IDI1LjczOTUgMjEuMjE1NSAyNi44NjU0QzIwLjA4OTcgMjcuOTkxMiAxOC41NjI3IDI4LjYyMzcgMTYuOTcwNSAyOC42MjM3QzE1LjM3ODQgMjguNjIzNyAxMy44NTE0IDI3Ljk5MTIgMTIuNzI1NSAyNi44NjU0QzExLjU5OTcgMjUuNzM5NSAxMC45NjcyIDI0LjIxMjYgMTAuOTY3MiAyMi42MjA0TDEwLjk2NzIgOS42MjM3NEMxMC45NjcyIDguNTYyMjkgMTEuMzg4OSA3LjU0NDMxIDEyLjEzOTQgNi43OTM3NEMxMi44OSA2LjA0MzE4IDEzLjkwOCA1LjYyMTUyIDE0Ljk2OTQgNS42MjE1MkMxNi4wMzA5IDUuNjIxNTIgMTcuMDQ4OSA2LjA0MzE4IDE3Ljc5OTQgNi43OTM3NEMxOC41NSA3LjU0NDMxIDE4Ljk3MTYgOC41NjIyOSAxOC45NzE2IDkuNjIzNzRMMTguOTY0NiAyMi42Mjc0QzE4Ljk2NDYgMjMuMTU4MiAxOC43NTM3IDIzLjY2NzIgMTguMzc4NSAyNC4wNDI0QzE4LjAwMzIgMjQuNDE3NyAxNy40OTQyIDI0LjYyODUgMTYuOTYzNSAyNC42Mjg1QzE2LjQzMjcgMjQuNjI4NSAxNS45MjM3IDI0LjQxNzcgMTUuNTQ4NSAyNC4wNDI0QzE1LjE3MzIgMjMuNjY3MiAxNC45NjI0IDIzLjE1ODIgMTQuOTYyNCAyMi42Mjc0TDE0Ljk2OTQgMTAuNjI3OCIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xM180MSI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTYuOTcwNikgcm90YXRlKC00NSkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 0;
    top: 4px;
    z-index: 1;
  }
  .file-label:after{
    content: 'Attach a File';
    display: block;
    text-decoration: underline;
    color: #000;
    position: absolute;
    left: 40px;
    top: 6px;
    width: auto;
    text-indent: 0;
    text-align: center;
  }
  .content-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    h3 {
      text-align: left;
      color: #999999;
      text-transform: none;
      letter-spacing: normal;
      font-weight: 600;
      margin-bottom: 0px;
      font-family: "Arial",sans-serif;
      @media (min-width: 768px) {
        text-align: left;
      }
    }
    p {
      font-size: .98rem;
    }
    a {
      color: #999999;
      text-decoration: none;
    }
    >p {
      margin-top: .5rem;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
      border-bottom: solid 3px #999999;
      @media (min-width: 768px) {
        text-align: left;
      }
    }
    .form-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      >div {
        width: calc(50% - 30px);
      }
      >div.wide{
        width: 100%;
      }
    }
    .contact-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      >div {
        width: 100%;
        @media(min-width: 1024px){
          width: calc(50% - 30px);
        }
      }
    }
    form {
      position: relative;
      overflow: hidden;

      .input-area {
        margin-bottom: 40px;
        position: relative;

        &.button-area {
          text-align: left;
          margin-bottom: 0;
        }
      }

      input:not([type="submit"]),
      textarea {
        height: 100%;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        padding: 0px;
        display: block;
        width: 100% !important;
        border: none;
        background-color: #ffffff;
        color: #999999;
        position: relative;
        box-sizing: border-box;
        outline: none;
        border-bottom: solid 1px #999999;
        /* &:focus,
        &:valid {
          + .label-name {
            .content-name {
              transform: translateY(-25%);
              font-size: 0.7rem;
              opacity: 0.2;
            }
            &::after {
              transform: translateX(0%);
            }
          }
        } */
      }
      .checkbox-area {
        display: flex;
        flex-direction: row;
      }
      .position-bottom {
        margin-top: 1rem;
      }
      @media(min-width: 1024px){
        .position-bottom {
          position: absolute;
          bottom: 0px;
          margin-bottom: 0px;
        }
      }
      input[type="checkbox"]{
        border: solid 3px #999999;
        height: 1rem;
        width: 1rem !important;
        margin-right: .5rem;
        display: inline-block;
      }
      input[type="submit"] {
        color: #999999;
        background: #ffffff;
        position: relative;
        display: inline-block;
        padding: 0px 20px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 0.85rem;
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        text-decoration: none;
        box-sizing: border-box;
        border: 3px solid rgb(153, 153, 153);
        z-index: 1;
        cursor: pointer;
      }
      input[type="submit"]:hover {
        color: #ffffff;
        background: #999999;
        animation: none;
      }
      input[type="submit"]:hover:before{
        display: none;
      }
      .content-name {
        position: absolute;
        top: 0px;
        left: 20px;
        transition: all 0.3s ease;
        text-transform: uppercase;
        letter-spacing: 0.25rem;
        font-size: 0.8rem;
      }
    }
  }
`

export default Contact
