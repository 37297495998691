import React, { Component } from 'react'

class FSSidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sidebarObject: null
    }
  }

  async componentDidMount() {
    let footerObject = null
    let sidebarObject = null
    let url = 'https://www.forms-surfaces.com/contact-us'
    let fetchResponse = await fetch(url)

    if(fetchResponse.status === 200){
      let pageText = await fetchResponse.text()
      var parser = new DOMParser();
      var doc = parser.parseFromString(pageText, "text/html");
      sidebarObject = doc.getElementById('sidebar-left').innerHTML;
      sidebarObject = sidebarObject.replaceAll('href="/','target="_parent" href="https://www.forms-surfaces.com/')
    }

    this.setState({
      sidebarObject: sidebarObject
    }, function(){
      console.log(this.state)
    })
  }


  render() {
    return(
      <div
      dangerouslySetInnerHTML={{__html: this.state.sidebarObject}}
      ></div>
    )
  }
}

export default(FSSidebar)
