import React, { Component } from 'react'
import styled from 'styled-components'

class FSFooter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      footerObject: null
    }
  }

  async componentDidMount() {
    console.log('componentDidMount')
    let footerObject = null
    let sidebarObject = null
    let url = 'https://www.forms-surfaces.com'
    let fetchResponse = await fetch(url)

    if(fetchResponse.status === 200){
      let pageText = await fetchResponse.text()
      var parser = new DOMParser();
      var doc = parser.parseFromString(pageText, "text/html");
      let social = doc.getElementById('social');
      let footerHTML = doc.getElementById('footer');
      let closureBlocks = doc.getElementById('closure-blocks');

      if(social){
        footerObject = '<div id="social">'+social.innerHTML+'</div>';
      }
      if(footerHTML){
        footerObject += '<div id="footer">'+footerHTML.innerHTML+'</div>';
      }
      if(closureBlocks) {
        footerObject += '<div id="closure-blocks">'+closureBlocks.innerHTML+'</div>';
      }
      if(footerObject){
        footerObject = footerObject.replaceAll('href="/','target="_parent" href="https://www.forms-surfaces.com/')
      }else{
        footerObject = ''
      }

    }

    this.setState({
      footerObject: footerObject,
    }, function(){
      console.log(this.state)
    })
  }


  render() {
    return(
      <FooterWrapper>
        <div
         dangerouslySetInnerHTML={{__html: this.state.footerObject}} />
      </FooterWrapper>
    )
  }
}

export default(FSFooter)

const FooterWrapper = styled.section`
font-weight: 100;
#social-inner, #footer-inner {
  margin-top: 11px;
}
#social {
  text-align: center;
  border-top: solid 1px #e5e5e5;
  margin-top: 20px;
  border-bottom: solid 1px #e5e5e5;
  p {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    line-height: 15px;
  }
  a.social-link {
    background: url(https://www.forms-surfaces.com/sites/default/themes/fs_revised/images/social-icons-flat.png);
    background-repeat: no-repeat;
    height: 33px;
    width: 33px;
    margin: 0 4px;
    text-indent: -999999px;
    overflow: hidden;
    display: inline-block;
    padding: 0;
    opacity: .3;
  }
  a.social-link.linkedin {
    background-position: -66px 0;
  }
  a.social-link.pinterest {
    background-position: -99px 0;
  }
  a.social-link.instagram {
    background-position: -33px 0;
  }
  a.social-link.facebook {
    background-position: 0 0;
  }
  a.social-link.youtube {
    background-position: -264px 0;
  }
  a.social-link.vimeo {
    background-position: -297px 0;
  }
}
#footer {
  width: 92.5%;
  margin: 0px auto;
  color: #999;
  position: relative;
  font-size: 0.75em;
  line-height: 1.333em;
}
#footer p {
    margin: 0 0 12px;
}
#footer-inner {
  margin-top: 11px;
  .block {
    &:nth-child(even) {
      border-left: 1px solid #e5e5e5;
    }
    padding-left: 10px;
    &:first-child {
      border-left: none;
    }
  }
}
#footer ul {
  margin: 0;
  padding: 0;
  li {
    padding-right: 20px;
    font-weight: 400;
    list-style-type: none;
    list-style-image: none;
    font-size: 1em;
    margin: 0;
    a {
      color: #999;
      text-decoration: none;
      font-weight: 100;
    }
  }
}
#footer .title {
  font-size: 1em;
  font-weight: 700;
  padding: 5px 0;
  color: #999;
  margin: 0;
}
#footer-logo {
    display: block;
    width: 38px;
    height: 38px;
    background: url(http://www.forms-surfaces.com/sites/default/themes/fs_revised/images/fs-logo-side.png?red=blue) center center no-repeat;
    position: absolute;
    right: 0;
    top: 15px;
}
#mc_embed_signup input.email {
    font-family: "Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif;
    font-size: 15px;
    display: block;
    padding: 0 0.4em;
    margin: 0 4% 10px 0;
    min-height: 32px;
    width: 58%;
    min-width: 130px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
#mc_embed_signup input {
    border: 1px solid #999;
    -webkit-appearance: none;
}
#mc_embed_signup input.button {
    display: block;
    width: 35%;
    margin: 0 0 10px 0;
    min-width: 90px;
    clear: both;
    background-color: #aaa;
    border: 0 none;
    border-radius: 4px;
    letter-spacing: .03em;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    height: 32px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    width: auto;
    transition: all 0.23s ease-in-out 0s;
    text-transform: uppercase;
    font-weight: 100;
    font-size: .75rem;
}
#mc_embed_signup .button:hover {
    background-color: #777;
}
#closure-blocks {
  .footer_block {
    clear: both;
    background: #fff;
    border-top: 1px solid #E5E5E5;
    font-size: 12px;
    margin-top: 0;
    padding: 15px 0 0;
    position: relative;
    text-transform: uppercase;
  }
  #footer_right_mod {
    margin: 0 auto;
    position: relative;
    height: 40px;
  }
  #footer_right_mod .footer-inner {
    text-align: right;
    position: relative;
    padding-right: 0;
  }
  .gBBf #closure-blocks .footer_block {
    clear: both;
    background: #fff;
    border-top: 1px solid #E5E5E5;
    font-size: 12px;
    margin-top: 0;
    padding: 15px 0 0;
    position: relative;
    text-transform: uppercase;
  }
  a:link {
    color: #808285;
    text-decoration: none;
    font-weight: 100;
  }
}
#footer-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div.block {
    min-height: 10rem;
    width: calc(50% - 1em);
  }
}
@media(min-width: 700px) and (max-width: 950px){
  #footer-inner div.block {
    width: calc(25% - 1em);
    border-left: solid 1px #E5e5e5;
    &.region-count-6,
    &:first-child {
      border-left: none;
    }
  }
}
@media(min-width: 950px){
  #footer-inner div.block {
    width: calc(20% - 1em);
    border-left: solid 1px #E5e5e5;
    &:first-child {
      border-left: none;
    }
  }
}
`
